import type { GridProps } from '@chakra-ui/react';
import { Box, Grid, Flex, Text, Link, VStack, Skeleton } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import NetworkAddToWallet from 'ui/shared/NetworkAddToWallet';

import FooterLinkItem from './FooterLinkItem';
import IntTxsIndexingStatus from './IntTxsIndexingStatus';

const MAX_LINKS_COLUMNS = 4;

const Footer = () => {
  const linksData = [
    {
      title: "Kyoto",
      links: [
        {
          icon: 'social/tweet' as const,
          iconSize: '18px',
          text: "Twitter",
          url: "https://twitter.com/KyotoRefi"
        },
        {
          icon: 'social/telega' as const,
          iconSize: '18px',
          text: "Telegram",
          url: "https://t.me/KyotoProtocol"
        },
        {
          icon: 'social/coinmarketcap' as const,
          iconSize: '18px',
          text: "CoinMarketCap",
          url: "https://coinmarketcap.com/currencies/kyoto-protocol/"
        },
      ]
    }
  ]

  const colNum = Math.min(linksData?.length || Infinity, MAX_LINKS_COLUMNS) + 1;

  const renderNetworkInfo = React.useCallback((gridArea?: GridProps['gridArea']) => {
    return (
      <Flex
        gridArea={ gridArea }
        flexWrap="wrap"
        columnGap={ 8 }
        rowGap={ 6 }
        mb={{ base: 5, lg: 10 }}
        _empty={{ display: 'none' }}
      >
        { !config.UI.indexingAlert.intTxs.isHidden && <IntTxsIndexingStatus/> }
        <NetworkAddToWallet/>
      </Flex>
    );
  }, []);

  const renderProjectInfo = React.useCallback((gridArea?: GridProps['gridArea']) => {
    return (
      <Box gridArea={ gridArea }>
        <Text mt={ 3 } fontSize="xs">
          Kyotoscan is a tool for inspecting and analysing the Kyoto Blockchain.
        </Text>
      </Box>
    );
  }, []);

  const containerProps: GridProps = {
    as: 'footer',
    px: { base: 4, lg: 12 },
    py: { base: 4, lg: 9 },
    borderTop: '1px solid',
    borderColor: 'divider',
    gridTemplateColumns: { base: '1fr', lg: 'minmax(auto, 470px) 1fr' },
    columnGap: { lg: '32px', xl: '100px' },
  };

  return (
    <Grid { ...containerProps }>
      <div>
        { renderNetworkInfo() }
        { renderProjectInfo() }
      </div>

      <Grid
        gap={{ base: 6, lg: colNum === MAX_LINKS_COLUMNS + 1 ? 2 : 8, xl: 12 }}
        gridTemplateColumns={{
          base: 'repeat(auto-fill, 160px)',
          lg: `repeat(${ colNum }, 135px)`,
          xl: `repeat(${ colNum }, 160px)`,
        }}
        justifyContent={{ lg: 'flex-end' }}
        mt={{ base: 8, lg: 0 }}
      >
        {
          linksData
            .slice(0, colNum)
            .map(linkGroup => (
              <Box key={ linkGroup.title }>
                <Skeleton fontWeight={ 500 } mb={ 3 } display="inline-block" isLoaded={ true }>{ linkGroup.title }</Skeleton>
                <VStack spacing={ 1 } alignItems="start">
                  { linkGroup.links.map(link => <FooterLinkItem { ...link } key={ link.text } isLoading={ false }/>) }
                </VStack>
              </Box>
            ))
        }
      </Grid>
    </Grid>
  );
};

export default React.memo(Footer);
