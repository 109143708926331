import { Box, Image, useColorModeValue, Skeleton, chakra } from '@chakra-ui/react';
import React from 'react';

import { route } from 'nextjs-routes';

import config from 'configs/app';

interface Props {
  isCollapsed?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
}

const LogoFallback = ({ isCollapsed, isSmall }: { isCollapsed?: boolean; isSmall?: boolean }) => {
  const logoColor = useColorModeValue('blue.600', 'white');
  const icon = useColorModeValue('/static/icon.svg', '/static/icon-white.svg');
  const logo = useColorModeValue('/static/logo.svg', '/static/logo-white.svg');

  return (
    <Skeleton isLoaded={ true } display="inline-block" width="auto" height="100%" color={ logoColor }>
      <img style={{width: "100%", height: "100%", position: "relative", left: "-7px"}} src={ isCollapsed ? icon : logo } />
    </Skeleton>
  );
};

const NetworkLogo = ({ isCollapsed, onClick }: Props) => {

  const logoSrc = useColorModeValue(config.UI.sidebar.logo.default, config.UI.sidebar.logo.dark || config.UI.sidebar.logo.default);
  const iconSrc = useColorModeValue(config.UI.sidebar.icon.default, config.UI.sidebar.icon.dark || config.UI.sidebar.icon.default);
  const darkModeFilter = { filter: 'brightness(0) invert(1)' };
  const logoStyle = useColorModeValue({}, !config.UI.sidebar.logo.dark ? darkModeFilter : {});
  const iconStyle = useColorModeValue({}, !config.UI.sidebar.icon.dark ? darkModeFilter : {});

  return (
    <Box
      as="a"
      href={ route({ pathname: '/' }) }
      width={{ base: '170px', lg: isCollapsed === false ? '170px' : '50px', xl: isCollapsed ? '50px' : '170px' }}
      height={{ base: '50px', lg: isCollapsed === false ? '50px' : '50px', xl: isCollapsed ? '50px' : '50px' }}
      display="inline-flex"
      overflow="hidden"
      onClick={ onClick }
      flexShrink={ 0 }
      aria-label="Link to main page"
    >
      { /* big logo */ }
      <Image
        w="auto"
        h="100%"
        src={ logoSrc }
        alt={ `${ config.chain.name } network logo` }
        fallback={ <LogoFallback isCollapsed={ isCollapsed }/> }
        display={{ base: 'block', lg: isCollapsed === false ? 'block' : 'none', xl: isCollapsed ? 'none' : 'block' }}
        style={ logoStyle }
      />
    </Box>
  );
};

export default React.memo(NetworkLogo);
